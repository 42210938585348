import React, {useEffect, useState} from 'react';
import {NavLink, withRouter} from "react-router-dom"
import Navigation from "./navigation";


const Header = ({history}) => {
  //State for menu button
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: "Menu"
  });

  //State for disabled button
  const [disabled, setDisabled] = useState(false);

  //UseEffect for page changes
  useEffect(() => {
    //  listen for pages changes
    history.listen(() => {
      setState({clicked: false, menuName: "Menu"})
    })
  });

  const handleMenu = () => {
    disabledMenu();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: "Close"
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: "Menu"
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: "Close"
      });
    }
  };

  //Determine if our menu button should be disabled
  const disabledMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1200)
  };

  return (
    <div className="header">
      <div className="container">
        <div className="row v-center space-between">
          <div className="logo">
            <NavLink to="/">Grupo C&C.</NavLink>
          </div>
          <div className="nav-toggle">
            <button disabled={disabled} onClick={handleMenu}>
              <div className="hamburger-menu">
                <span/>
                <span/>
                <span/>
              </div>
            </button>
          </div>
        </div>
      </div>
      <Navigation state={state}/>
    </div>
  );
};

export default withRouter(Header);