import React from 'react';
import especialidad from "../assets/img/background_especialidad.png";

const Especialidad = () => {
  return (
    <section className="especialidad">
      <div className="background-especialidad">
        <img src={especialidad} alt="Especialidad background"/>
      </div>
      <div className="opacity-especialidad"/>
      <div className="container">
        <div className="row">
          <h2>
            <div className="text-title">
              <span>Especialidades</span>

            </div>
            <div className="text-description">
              <span>
              Somos un despacho jurídico conformado por especialistas en la materia inmobiliaria, donde a su vez se gestan sub especialidades en el ramo.
              </span>
            </div>
          </h2>
        </div>
      </div>
    </section>
  );
};

export default Especialidad;