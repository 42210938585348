import React, {Component} from 'react';

class Contacto extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }

  handleChangeForm = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({[name]: value})
  };

  render() {
    const {name, email, message} = this.state;
    const enabled = name.length > 0 && email.length > 0 && message.length > 0;

    return (
      <section className="contactanos">
        <div className="container">
          <div className="row">
            <div className="content">
              <h2>
                <div className="title">
                  <span>Contactanos</span>
                </div>
              </h2>
              <div className="container">
                <div className="form">
                  <form method="POST" action="https://formspree.io/hugo@grupocyc.com.mx">
                    <div className="input">
                      <input name="name" id="name" type="text" placeholder="Nombre" value={name} onChange={this.handleChangeForm}/>
                    </div>
                    <div className="input">
                      <input name="email" id="email" type="text" placeholder="Correo" value={email} onChange={this.handleChangeForm}/>
                    </div>
                    <div className="input">
                      <textarea name="message" id="message" placeholder="Mensaje" style={{height: "200px"}} value={message} onChange={this.handleChangeForm}/>
                    </div>
                    <div className="btn-form">
                      <button disabled={!enabled} type="submit">Enviar</button>
                    </div>
                  </form>
                </div>
                <div className="info">
                  <div className="info-line">
                    <span><i className="fas fa-envelope fa-2x"/></span>
                    <span>
                    <a href="mailto: hugo@grupocyc.com.mx">
                      hugo@grupocyc.com.mx
                    </a>
                  </span>
                  </div>
                  <div className="info-line">
                    <span><i className="fas fa-phone fa-2x"/></span>
                    <div className="phone">
                      <span>442-2501133</span>
                      <span>442-4638567</span>
                      <span>442-9800522</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contacto;