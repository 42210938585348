import React, {useEffect} from 'react';
import Icon from "../assets/img/logo.png";
import background_home from "../assets/img/background_home.png";

const Banner = ({animationComplete}) => {

  useEffect(() => {
    if(!animationComplete) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [animationComplete]);

  console.log(animationComplete)
  return (
    <>
      <div className="image-background">
        <img src={background_home} alt="Background Main Home"/>
      </div>
      <div className="opacity"/>
      <section className="main">
        <div className="container">
          <div className="icon-logo">
            <img src={Icon} alt="Grupo C&C Icon"/>
          </div>
          <div className="content-row">
            <h2>
              <div className="line">
                <span>Despacho jurídico conformado por profesionistas</span>
              </div>
              <div className="line">
                <span>estudiosos de la materia inmobiliaria.</span>
              </div>
            </h2>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
