import React, {useEffect, useState} from 'react';
import {Route} from "react-router-dom";
import gsap from "gsap";

//Styles
import "./styles/App.scss";
import './App.css';

//Pages
import Home from "./pages/home";
import Hipotecaria from "./pages/hipotecaria";
import Energetica from "./pages/energetica";
import Tierra from "./pages/tierra";

//Components
import Header from "./components/header";
import Navigation from "./components/navigation";

const routes = [
  {path: "/", name: "Home", Component: Home},
  {path: "/hipotecaria", name: "Materia hipotecaria", Component: Hipotecaria},
  {path: "/energetica", name: "Materia energetica", Component: Energetica},
  {path: "/tierra", name: "Regulacion de la tierra", Component: Tierra},
];

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments)
    }, ms)
  }
}

function App() {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    //prevents flashing
    gsap.to("body", 0, {css: {visibility: "visible"}});

    let vh = dimensions.height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const debounceHanldeResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 1000);

    window.addEventListener('resize', debounceHanldeResize)

    return () => {
      window.removeEventListener('resize', debounceHanldeResize);
    };

  }, [])

  return (
    <>
      <Header/>
      <div className="App">
        {routes.map(({path, Component}) => (
          <Route key={path} exact path={path}>
            <Component/>
          </Route>
        ))}
      </div>
    </>
  );
}

export default App;
