import React from 'react';
import Icon from "../assets/img/logo.png";
import {Link} from "react-router-dom";

const Footer = ({executeScroll}) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="information-footer">
            <div className="info">
              <i className="fas fa-map-marker-alt fa-2x"/>
              <span>Avenida Antea, 1088, Colonia Jurica Querétaro</span>
            </div>
            <div className="info">
              <i className="fas fa-envelope fa-2x"/>
              <span>
                <a href="mailto: hugo@grupocyc.com.mx">
                  hugo@grupocyc.com.mx
                </a>
              </span>
            </div>
            <div className="info">
              <i className="fas fa-phone fa-2x"/>
              <div className="phone">
                <span>442-2501133</span>
                <span>442-4638567</span>
                <span>442-9800522</span>
              </div>
            </div>
          </div>
          <div className="logo">
            <img src={Icon} alt="Logo Grupo C&C"/>
          </div>
          <div className="about-footer">
            <div className="about-info">
              <span>Despacho jurídico conformado por profesionistas estudiosos de la materia inmobiliaria.</span>
              <div className="btn-info">
                <Link to="/" onClick={executeScroll}>Ver más</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;