import React from 'react';
import {ReactComponent as IconEnergetica} from "../assets/img/energetica.svg";
import {ReactComponent as IconTierra} from "../assets/img/tierra.svg";
import {ReactComponent as IconHipotecaria} from "../assets/img/hipotecaria.svg";
import {Link} from "react-router-dom";

const items = [
  {
    path: "/hipotecaria",
    name: "Hipotecaria",
    Component: IconEnergetica,
    description: "Conocemos ampliamente los tres campos de trabajo que versan de ésta como son: Judicial, Extrajudicial y Administrativo."
  },
  {
    path: "/energetica",
    name: "Energetica",
    Component: IconTierra,
    description: "En materia energética brindamos apoyo integral en sus tres fases: Adquisición Inmobiliaria, Supervisión y de Auditoría."
  },
  {
    path: "/tierra",
    name: "Regulación de la tierra",
    Component: IconHipotecaria,
    description: "La regularización de la tenencia de la tierra la abordamos con base en la naturaleza de la misma: Ejidal y Privada.\n" +
      "Siempre en todo momento tratando de simplificar los tiempos y procesos, en beneficio de nuestro cliente, y en pro de su satisfacción."
  },
];

const ItemEspecialidad = () => {
  return (
    <section className="item-especialidad">
      <div className="container">
        <div className="row">
          <div className="container-item">
            {items.map(({path, name, Component, description}) => (
              <>
                <div key={name} className="item">
                  <div className="icon-item">
                    <Component/>
                  </div>
                  <div className="icon-title">
                    <span>{name}</span>
                  </div>
                  <div className="icon-description">
                    <span>{description}</span>
                  </div>
                  <div className="icon-btn">
                    <Link to={path}>Conoce más</Link>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ItemEspecialidad;