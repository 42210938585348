import React, {useEffect} from 'react';
import energetica from "../assets/img/energetica.png";
import Footer from "../components/footer";

const Energetica = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="section-text">
              <h2>
                <div className="text-title">
                  <span>Materia Energetica</span>
                </div>
                <div className="text-description">
              <span>
                Adquisición Inmobiliaria (Derecho de vía)

                <p>
                  Localizando, negociando y contratando los inmuebles necesarios para infraestructura de parques fotovoltaicos, parques eólicos, gasoductos, oleoductos, líneas de transmisión, subestaciones, gasolineras etc., extendiéndonos hasta el campo de las comunicaciones como los son líneas ferroviarias, carreteras, inmuebles para instalación de antenas, etc., siempre supervisando la legalidad de los documentos con los que se pretende acreditar la propiedad, ya sea que tengan una naturaleza de derecho privado o bien de derechos agrario (ejido o parcela), lo que nos vuelve un despacho completo al no limitarse a propiedades privadas, sino a trabajar con propiedades ejidales para proyectos productivos en esta misma así como gestionando los permisos municipales, locales y federales que se necesitan.
                </p>
              </span>
                </div>
                <div className="text-description">
              <span>
                Supervisión
                <p>
                  Verificamos de forma objetiva que se cumplan con todos los lineamientos de los proyectos energéticos en materia legal para el debido registro de los contratos que avalan, la adquisición de lo derechos inmobiliarios, dictaminando procesos de adquisición de derechos de via antes, durante y despues de su adquisición, mediante la generación y cumplimiento de manuales de adquisición inmobiliaria que respeten el marco legal (incluyendo la reforma energética).
                </p>
              </span>
                </div>
                <div className="text-description">
              <span>
                Auditoría
                <p>
                  Analizamos el apego legal y contractual de la adquisición del derecho de vía solicitada por los los bancos y/o interesados, mediante la implementación de una due diligence legal, que permitan los financiamientos y/o adquisiciones de recursos para los proyectos.
                </p>
              </span>
                </div>
              </h2>
            </div>
            <div className="image-section">
              <img src={energetica} alt="Image Section"/>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Energetica;