import React from 'react';

const IntroOverlay = () => {
  return (
    <>
      <div className="intro-overlay">
        <div className="all">
          <div className="overlay-top"/>
          <div className="overlay-top"/>
          <div className="overlay-top"/>
        </div>
      </div>
    </>
  );
};

export default IntroOverlay;