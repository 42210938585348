import React, {useEffect} from 'react';
import tierra from "../assets/img/tierra.png";
import Footer from "../components/footer";

const Tierra = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="section-text">
              <h2>
                <div className="text-title">
                  <span>Regulación de tierra</span>
                </div>
                <div className="text-description">
              <span>
                <p>
                  Verificando procesos judiciales (sucesiones testamentarias, intestamentarias, prescripciones (usucapion), hipotecarios, etc.,) o bien de carácter administrativo cesión de derechos agrarios, reconocimientos del ejido, contratos de compra-venta, donación etc.Siempre en todo momento tratando de simplificar los tiempos y procesos, en beneficio de nuestro cliente, y en pro de su satisfacción.
                </p>
              </span>
                </div>
                <div className="text-description">
              <span>
                <p>
                  Nuestro Bufete aborda el tema de la regularización de la tenencia de la tierra, como una necesidad de los propietarios, pero también de aquellos interesados en celebrar un contrato en dichas tierras: compra-venta, arrendamiento, usufructo, ocupación superficial, servidumbre etc.
                </p>
              </span>
                </div>
              </h2>
            </div>
            <div className="image-section">
              <img src={tierra} alt="Image Section"/>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Tierra;