import React, {useEffect, useRef} from 'react';
import {Link, NavLink} from "react-router-dom";
import gsap from "gsap";

const Navigation = ({state}) => {
  //Vars for our animated does nodes
  let menu = useRef(null);
  let revealMenu = useRef(null);
  let revealMenuBackground = useRef(null);
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);
  let info = useRef(null);

  useEffect(() => {
    if (state.clicked === false) {
      // menu.style.display = "none";
      gsap.to([revealMenu, revealMenuBackground], {
        duration: 0.8,
        height: 0,
        ease: "power3.inOut",
        stagger: {
          amount: 0.07
        }
      });

      gsap.to(menu, {
        duration: 1,
        css: {display: "none"}
      });
      // eslint-disable-next-line no-mixed-operators
    } else if (state.clicked === true || state.clicked === true && state.initial === null) {
      // menu.style.display = "block";
      gsap.to(menu, {
        duration: 0,
        css: {display: "block"}
      });
      gsap.to([revealMenuBackground, revealMenu], {
        duration: 0,
        opacity: 1,
        height: "100vh"
      });

      staggerReveal(revealMenuBackground, revealMenu);

      fadeInUp(info);

      staggerText(line1, line2, line3);
    }
  }, [state]);

  const staggerReveal = (node1, node2) => {
    gsap.from([node1, node2], {
      duration: .8,
      height: 0,
      transformOrigin: "right top",
      skewY: 2,
      ease: "power3.inOut",
      stagger: {
        amount: 0.1
      }
    })
  };

  const fadeInUp = (node) => {
    gsap.from(node, {
      y: 60,
      duration: 1,
      delay: .2,
      opacity: 0,
      ease: "power3.inOut"
    })
  };

  const staggerText = (node1, node2, node3) => {
    gsap.from([node1, node2, node3], {
      duration: .8,
      y: 100,
      delay: .2,
      ease: "power3.inOut",
      stagger: {
        amount: .3
      }
    })
  };

  return (
    <div className="hamburguer-menu" ref={el => (menu = el)}>
      <nav>
        <div ref={el => (revealMenuBackground = el)} className="menu-secondary-background-color">
          <div ref={el => (revealMenu = el)} className="menu-layer">
            <div className="container">
              <div className="nav-columns">
                <div className="nav-column">
                  <div className="nav-label">Menu</div>
                  <div className="nav-links">
                    <ul>
                      <li>
                        <Link ref={el => (line1 = el)} to="/hipotecaria">
                          Hipotecaria
                        </Link>
                      </li>
                      <li>
                        <Link ref={el => (line2 = el)} to="/energetica">
                          Energética
                        </Link>
                      </li>
                      <li>
                        <Link ref={el => (line3 = el)} to="/tierra">
                          Tierra
                        </Link>
                      </li>
                    </ul>
                  </div>

                </div>
                <div className="nav-column">
                  <div className="nav-label">Contacto</div>
                  <div ref={el => (info = el)} className="nav-infos">
                    <ul className="nav-info">
                      <li className="nav-info-label">
                        Correo
                      </li>
                      <li>
                        <a href="mailto: hugo@grupocyc.com.mx">
                          Contactate con nosotros
                        </a>
                      </li>
                    </ul>
                    <ul className="nav-info">
                      <li className="nav-info-label">Dirección</li>
                      <li>Avenida Antea</li>
                      <li>1088, Colonia Jurica</li>
                      <li>Querétaro</li>
                    </ul>
                    <ul className="nav-info">
                      <li className="nav-info-label">Telefono</li>
                      <li>442-9800522</li>
                    </ul>
                    <ul className="nav-info">
                      <li className="nav-info-label">Legal</li>
                      <li>Política de privacidad y cookies</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;