import React, {useEffect} from 'react';
import hipotecaria from "../assets/img/hipotecaria.png";
import Footer from "../components/footer";

const Hipotecaria = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="section-text">
              <h2>
                <div className="text-title">
                  <span>Materia Hipotecaria</span>
                </div>
                <div className="text-description">
              <span>
                Judicial
                <p>
                  Nuestro despacho se encarga de brindar su representación en los juicios (juicios hipotecarios) y sus
                  procedimientos derivados como los son: apelaciones, ejecuciones, incidentes y amparos, hasta la total
                  conclusión y éxito del asunto.
                </p>
              </span>
                </div>
                <div className="text-description">
              <span>
                Extrajudicial
                <p>
                  Nuestro despacho busca mediante una etapa previa y sin conflictos lograr una solución a los intereses
                  de nuestros clientes y el de sus deudores, por lo que inicia un procedimiento extrajudicial para la
                  firma de convenios. Asimismo trabajamos carteras donde el interés principal es la de una buena
                  administración de los bienes hipotecados, iniciando con la localización del inmueble, el estado que
                  guarda y la persona que lo posee (o en su caso su abandono que generará una dictaminación de bienes
                  abandonados) documentando de forma amplia dicha situación mediante fotos, actas administrativas ,
                  ubicación KMZ, avalúos y fe notarial.
                </p>
              </span>
                </div>
                <div className="text-description">
              <span>
                Administrativo
                <p>
                  En esta materia nuestros asesores brindan el apoyo para:
                  <br/>
                  A) CONSTITUCIÓN de hipotecas que apoyen o auxilien la adquisición de viviendas o garanticen un adeudo.
                  <br/>
                  B) El CAMBIO DE ACREEDORES O SUBSTITUCIÓN DE LA HIPOTECA en beneficio de ambas partes.
                  <br/>
                  C) LA CANCELACIÓN DE LA HIPOTECA, cuando así proceda por liquidación de adeudo o prescripción.
                  <br/>
                  D) AUDITAMOS el estatus que guardan dichas hipotecas desde el punto de vista legal (contrato de
                  hipoteca) y físico (el inmueble).
                </p>
              </span>
                </div>
              </h2>
            </div>
            <div className="image-section">
              <img src={hipotecaria} alt="Image Section"/>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Hipotecaria;