import React, {useEffect, useRef, useState} from 'react';
import gsap from "gsap";

//Components
import Banner from "../components/banner";
import IntroOverlay from "../components/introOverlay";
import About from "../components/about";
import Especialidad from "../components/especialidad";
import ItemEspecialidad from "../components/itemEspecialidad";
import CoWork from "../components/coWork";
import Contacto from "../components/contacto";
import Footer from "../components/footer";

const tl = gsap.timeline();

const homeAnimation = (completeAnimation) => {
  tl.to('.overlay-top', 1.6, {
    height: 0,
    ease: 'expo.inOut',
    stagger: 0.4
  }).from(".line span", 1, {
    y: 100,
    ease: 'power3.out',
    skewY: 7,
    stagger: {
      amount: .2
    }
  }).to('.icon-logo img', 1.6, {
    scale: 1.4,
    ease: "expo.inOut",
    opacity: 1,
    delay: -3,
    stagger: {
      amount: 0.4
    },
    onComplete: completeAnimation
  })
};

const scrollToRef = (ref) => window.scrollTo({top: ref.current.offsetTop, behavior: "smooth"})

const Home = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  const completeAnimation = () => {
    setAnimationComplete(!animationComplete);
  };

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    //  On load timeline
    homeAnimation(completeAnimation)
  }, []);

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  return (
    <>
      {animationComplete === false ? <IntroOverlay/> : ""}
      <Banner animationComplete={animationComplete}/>
      <About myRef={myRef}/>
      <Especialidad/>
      <ItemEspecialidad/>
      <CoWork/>
      <Contacto/>
      <Footer executeScroll={executeScroll}/>
    </>


  );
};

export default Home;