import React from 'react';
import work from "../assets/img/work.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const companies = [
  {id: 0, name: "Engie S.A de C.V"},
  {id: 1, name: "Engie México S.A de C.V"},
  {id: 2, name: "IDAACSA"},
  {id: 3, name: "TAG Pipelines Sur S.A de R.L"},
  {id: 4, name: "OIL&GAS S.A de C.V"},
  {id: 5, name: "Recursos Solares PV.México S.A de C.V"},
  {id: 6, name: "J&E Energía S.A de C.V"},
  {id: 7, name: "Grupo México S.A de C.V"},
];

const CoWork = () => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    adaptHeight: true
  };

  return (
    <section className="co-work">
      <div className="background-work">
        <img src={work} alt="Background work"/>
      </div>
      <div className="opacity-work"/>
      <div className="container">
        <div className="row">
          <div className="work-title">
            <span>Con quien hemos trabajado</span>
          </div>
          <div className="work-info">
            <span>Actualmente trabajamos con empresas de alto reconocimiento internacional.</span>
          </div>
          <div className="companies">
            <Slider {...settings}>
              {companies.map(({id, name}) => (
                <span key={id}>
                  {name}
                </span>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoWork;