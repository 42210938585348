import React from 'react';
import about from "../assets/img/about_us.png";

const About = ({myRef}) => {
  return (
    <section ref={myRef} className="about">
      <div className="container">
        <div className="about-text">
          <h2>
            <div className="text-title">
              <span>¿Quienes Somos?</span>

            </div>
            <div className="text-description">
              <span>
              Somos un despacho jurídico conformado por profesionistas estudiosos de la materia inmobiliaria en sus
              aspectos legales, judiciales, extrajudiciales y administrativos.

              Nuestro objetivo es la satisfacción del cliente basado en la experiencia y especialidad que en la materia
              tienen nuestros profesionistas ya que cuentan con estudios de postgrado y con más de 20 años de
              experiencia en su ramo.
              </span>
            </div>
          </h2>
        </div>
        <div className="background-about">
          <img src={about} alt=""/>
        </div>
      </div>
    </section>
  );
};

export default About;